import React from 'react';
import { motion } from 'framer-motion';

const Error404 = () => {
	return (
		<div className="error404">
			<motion.div
				className="text-header"
				animate={{ rotate: [6, -3, 6] }}
				transition={{ duration: 6, repeat: Infinity }}
			>
				Lost?
			</motion.div>
			<motion.div
				className="text-subtitle"
				animate={{ scale: [0.95, 1, 0.95], rotate: [-2, 1, -2]}}
				transition={{ duration: 6, repeat: Infinity }}
			>
				There is nothing in this space!
			</motion.div>
		</div>
	);
};

export default Error404;
