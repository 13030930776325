import React from 'react'
import { motion } from 'framer-motion';

const Hero = () => {
	return (
		<div class="hero columns is-mobile">
			<div class="hero-body has-text-right column is-6 is-offset-1">
				<motion.p
					style={{ originX: 0 }}
					whileHover={{ scale: 1.02 }}
					transition={{ duration: 0.3 }}
					class="has-text-primary is-size-1-widescreen is-size-2-desktop is-size-5-touch"
				>
					Hi, I'm Michael
				</motion.p>
				<motion.p
					style={{ originX: 0 }}
					whileHover={{ scale: 1.02 }}
					transition={{ duration: 0.3 }}
					class="hero-subtitle is-size-6-desktop"
				>
					Web Developer - 3D Modeler
				</motion.p>
			</div>
		</div>
	);
}

export default Hero