import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faHouse,
	faAt,
} from '@fortawesome/free-solid-svg-icons';
// import { faHouse, faCubes ,faUser, faLaptopCode, faAt } from '@fortawesome/free-solid-svg-icons'
// import Nav from './Nav';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import logo from '../images/logo.webp'

const Header = () => {
	return (
		<nav
			className="navbar is-transparent"
			role="navigation"
			aria-label="main navigation"
		>
			<div className="navbar-brand">
				<motion.div
					style={{ originX: 0 }}
					whileHover={{ scale: 1.05 }}
					transition={{ duration: 0.3 }}
				>
					<Link to="/" className="navbar-ite logo">
						<img src={logo} alt="logo" />
					</Link>
				</motion.div>

				{/* <a
					role="button"
					className="navbar-burger"
					aria-label="menu"
					aria-expanded="false"
					data-target="navMenu"
				>
					<span aria-hidden="true"></span>
					<span aria-hidden="true"></span>
					<span aria-hidden="true"></span>
				</a> */}
			</div>

			<div className="navbar-menu" id="navMenu">
				<div className="navbar-end">
					<Link to="/" className="navbar-custom-item">
						<FontAwesomeIcon icon={faHouse} />
					</Link>
					{/* <a className="navbar-custom-item dev-link">
						<FontAwesomeIcon icon={faLaptopCode} />
					</a>
					<a className="navbar-custom-item three-d-link">
						<FontAwesomeIcon icon={faCubes} />
					</a>

					<a className="navbar-custom-item about-link">
						<FontAwesomeIcon icon={faUser} />
					</a> */}
					<Link to="/contact" className="navbar-custom-item contact-link">
						<FontAwesomeIcon icon={faAt} />
					</Link>
				</div>

				{/* <div className="navbar-end">
      <div className="navbar-item">
        <div className="buttons">
          <a className="button is-primary">
            <strong>Sign up</strong>
          </a>
          <a className="button is-light">
            Log in
          </a>
        </div>
      </div>
    </div> */}
			</div>
		</nav>
	);
};

export default Header;
