import React from 'react';
import Hero from '../components/Hero';
// import SvgLogo from '../components/SvgLogo'
// import CloneExample from '../components/three/CloneExample';
// import Ninjasvgheader from '../components/testlayout/Ninjasvgheader';
import '../components/testlayout/ninjasvg.css';
import motherboardImg from '../images/motherboard.webp'
// import HeroBanner from '../components/HeroBanner';
// import logo from '../mgk_logo.svg'
// import { motion } from 'framer-motion';
// import { ReactSVG } from 'react-svg';

// https://danmarshall.github.io/google-font-to-svg-path/

const Home = () => {
	return (
		<>
			{/* <div className="home-wrapper"> */}
				{/* <SvgLogo/> */}
				{/* <Ninjasvgheader /> */}

				<div className="spacer"></div>
				<Hero />
				{/* <HeroBanner /> */}
				{/* <hr/> */}
				{/* <div className="hero">sadf</div> */}
				<div className="home-img">
					{/* h */}
					<img className="" src={motherboardImg} alt="" />
				</div>
				{/* <CloneExample /> */}
			{/* </div> */}
		</>
	);
};

export default Home;
