import {useState, useRef} from 'react';
import { useFormik } from 'formik';
import emailjs from '@emailjs/browser';
import { NavLink } from 'react-router-dom';


// A custom validation function. This must return an object
// which keys are symmetrical to our values/initialValues
const validate = (values) => {
	const errors = {};
	if (!values.firstName) {
		errors.firstName = 'Required';
	} else if (values.firstName.length > 15) {
		errors.firstName = 'Must be 15 characters or less';
	}

	if (!values.lastName) {
		// errors.lastName = 'Required';
	} else if (values.lastName.length > 20) {
		errors.lastName = 'Must be 20 characters or less';
	}

	if (!values.email) {
		errors.email = 'Required';
	} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
		errors.email = 'Invalid email address';
	}

	if(!values.phone) {
		// errors.phone = 'Required';
	} else if (!/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/i.test(values.phone)){
		errors.phone = 'Invalid phone number. Format: 123-456-7890';
	}

	if(!values.comments){
		errors.comments = 'Required';
	} 

	return errors;
};

export const ContactForm = () => {
	const form = useRef()
	const [redirect, setRedirect] = useState(false)
	const formik = useFormik({
		initialValues: {
			firstName: '',
			lastName: '',
			email: '',
			company: '',
			phone: '',
			comments: '',
		},
		validate,
		onSubmit: (values) => {
			// alert(JSON.stringify(values, null, 2));
			sendEmail(values)
		},
	});
	
	const sendEmail = (values) =>{
		// emailjs
		emailjs.sendForm(
			'service_ed623ph',
			'template_myportfolio',
			form.current,
			'user_46gaiJ86keAYnbz1AWxQU'
		)
		.then(result=> {
			console.log(result.text)
			setRedirect(true);
		}, error=>{
			console.log(error.text)
		})
		// .send('service_ed623ph', 'template_0117med', values,"user_46gaiJ86keAYnbz1AWxQU")
		// .then(
		// 	(result) => {
		// 		setRedirect(true)
		// 		},
		// 		(error) => {
		// 			console.log(error.text);
		// 		}
		// 	);
	}

	return (
		<div>
			{!redirect ? (
				<div className="has-text-centered contact-form-wrapper">
					<div className="is-size-3 has-text-orange mb-4">Contact Me</div>
					<form ref={form} onSubmit={formik.handleSubmit}>
						<div className="field is-horizontal">
							<div className="field-label is-normal">
								<label className="label">Name</label>
							</div>
							<div className="field-body">
								<div className="field">
									<p className="control is-expanded">
										<input
											className="input"
											id="firstName"
											name="firstName"
											type="text"
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.firstName}
											placeholder="First"
										/>
									</p>
									{formik.touched.firstName && formik.errors.firstName ? (
										<div className="has-text-danger">
											{formik.errors.firstName}
										</div>
									) : null}
								</div>
								<div className="field">
									<p className="control is-expanded">
										<input
											className="input"
											id="lastName"
											name="lastName"
											type="text"
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.lastName}
											placeholder="Last"
										/>
									</p>
									{formik.touched.lastName && formik.errors.lastName ? (
										<div className="has-text-danger">
											{formik.errors.lastName}
										</div>
									) : null}
								</div>
							</div>
						</div>

						<div className="field is-horizontal">
							<div className="field-label is-normal">
								<label className="label">Email</label>
							</div>
							<div className="field-body">
								<div className="field">
									<div className="control">
										<input
											className="input"
											id="email"
											name="email"
											type="email"
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.email}
											placeholder="Email Address"
										/>
										{formik.touched.email && formik.errors.email ? (
											<div className="has-text-danger">
												{formik.errors.email}
											</div>
										) : null}
									</div>
								</div>
							</div>
						</div>

						<div className="field is-horizontal">
							<div className="field-label is-normal">
								<label className="label">Company</label>
							</div>
							<div className="field-body">
								<div className="field">
									<div className="control">
										<input
											className="input"
											id="company"
											name="company"
											type="company"
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.company}
											placeholder="Company"
										/>
										{formik.touched.company && formik.errors.company ? (
											<div className="has-text-danger">
												{formik.errors.company}
											</div>
										) : null}
									</div>
								</div>
							</div>
						</div>

						<div className="field is-horizontal">
							<div className="field-label is-normal">
								<label className="label" htmlFor="phone">
									Phone Number
								</label>
							</div>
							<div className="field-body">
								<div className="field">
									<div className="control">
										<input
											className="input"
											id="phone"
											name="phone"
											type="phone"
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.phone}
											placeholder="123-456-7890"
										/>
									</div>
									{formik.touched.phone && formik.errors.phone ? (
										<div className="has-text-danger">{formik.errors.phone}</div>
									) : null}
								</div>
							</div>
						</div>

						<div className="field is-horizontal">
							<div className="field-label is-normal">
								<label className="label" htmlFor="comments">
									Comments
								</label>
							</div>
							<div className="field-body">
								<div className="field">
									<div className="control">
										<textarea
											className="textarea"
											id="comments"
											name="comments"
											type="comments"
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.comments}
										/>
									</div>
									{formik.touched.comments && formik.errors.comments ? (
										<div className="has-text-danger">
											{formik.errors.comments}
										</div>
									) : null}
								</div>
							</div>
						</div>

						<button className="button is-link my-4" type="submit">
							Submit
						</button>
					</form>
				</div>
			) : (
				<div className="has-text-centered  p-4 contact-form-wrapper">
					<p className="title has-text-white m-4">
						Your Comment Was Successfully Sent
					</p>
					<p className="subtitle has-text-orange m-2">
						Thank you for reaching out!
					</p>

					<NavLink to="/" className="button is-link my-4">
						OK
					</NavLink>
				</div>
			)}
		</div>
	);
};

export default ContactForm