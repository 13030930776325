import React from 'react';
import Helmet from 'react-helmet';
import ContactForm from '../components/ContactForm'

const Contact = () => {
	
	return (
		<div className="main">
			<Helmet>
				<title>Michael Kroth - Contact Me</title>
				<meta name="Michael Kroth - Contact Me" />
			</Helmet>
			<div className="colunms">
			<div className="column is-half is-offset-one-quarter mt-3">
				<ContactForm />
			</div>

			</div>
		</div>
	);
};

export default Contact;
