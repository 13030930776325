// import { useState } from 'react';
import './sass/App.scss';
import './sass/MyStyles.scss';
import { Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Home from './pages/Home';
import Footer from './components/Footer';
import Contact from './pages/Contact';
import Error404 from './pages/Error404';
// import Header from './components/testlayout/header';
// import Home from './components/testlayout/main';
// import Footer from './components/testlayout/footer';

function App() {
	return (
		<div className="layout">
			<Header />
			<Routes>
				<Route path="/" element={<Home />}></Route>
				<Route path="/contact" element={<Contact />}></Route>
				<Route path="*" element={<Error404 />}></Route>
			</Routes>
			<Footer />
		</div>
	);
}

export default App;
